// NOTE: don't put much in this file, prefer aw/ga instead. This is just to hide
// the gross gtag prettier/eslint/ts escapes.

export function gtagEnsure() {
  // prettier-ignore
  // @ts-expect-error gtag expects globals
  window.dataLayer = window.dataLayer || [];
  // prettier-ignore
  // @ts-expect-error gtag is untyped
  // eslint-disable-next-line prefer-rest-params
  window.gtag = window.tag || function gtag() { dataLayer.push(arguments); };
  return gtag;
}
